import style from "./For.module.css"
import imgForMain from "../../Pages/Course/IMG/photoFor 1.png"
import imgBoll from "../Course/IMG/Ellipse 1.png"
import imgError from "../Course/IMG/error.png"

export function For() {
    return (
        <div className={style.forBlock}>
            <div className={style.textForMain}>
                курс подходит
            </div>
            <div className={style.textForSecond}>
                для тех, у кого:
            </div>

            <img src={imgForMain} className={style.imgForMain} />
            <div className={style.forTextMedico}>
                а также для тех, у кого имеются медицинские показания:
            </div>
            <div className={style.Block}>
                <div className={style.miniBlock1}>
                    <p className={style.textMini1}>болит спина</p>
                </div>
                <div className={style.miniBlock1}>
                    <p className={style.textMini1}>зажат грудной отдел</p>
                </div>
                <div className={style.miniBlock1}>
                    <p className={style.textMini1}>боли в шейном отделе</p>
                </div>
                <div className={style.miniBlock1}>
                    <p className={style.textMini1}>сутулость</p>
                </div>
                <div className={style.miniBlock1}>
                    <p className={style.textMini1}>отечность</p>
                </div>
                <div className={style.miniBlock1}>
                    <p className={style.textMini1}>головные боли</p>
                </div>
                <div className={style.miniBlock1}>
                    <p className={style.textMini1}>выпирает низ живота</p>
                </div>
            </div>
            <div className={style.blockBoll}>
                <div className={style.boll}>
                    <div>
                        <svg className={style.imgMiniBoll1} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" >
                            <circle cx="14" cy="14" r="14" fill="#54000E" />
                        </svg>
                    </div>
                    <div>
                        <svg className={style.imgMiniBoll2} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <circle cx="14" cy="14" r="14" fill="#54000E" />
                        </svg>
                    </div>
                    <img src={imgBoll} className={style.imgForBoll} />
                </div>

                <div>
                    <svg className={style.imgMiniBoll3} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <circle cx="14" cy="14" r="14" fill="#54000E" />
                    </svg>
                </div>
                <div>
                    <svg className={style.imgMiniBoll4} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <circle cx="14" cy="14" r="14" fill="#54000E" />
                    </svg>
                </div>
            </div>
            <div className={style.miniText1}>
                <div>
                    межпозвоночная
                    <p>грыжа </p>
                </div>
            </div>
            <div className={style.miniText2}>
                <div>
                    лордоз
                    <p>
                        и
                    </p>
                    <p>
                        кифоз
                    </p>
                </div>
            </div>
            <div className={style.miniText3}>
                <div>
                    сколиоз
                </div>
            </div>
            <div className={style.miniText4}>
                <div>
                    остеохондроз
                </div>
            </div>
            <div className={style.blockEthenshen}>
                <img src={imgError} className={style.imgEtn} />

                <div className={style.textEtn}>
                    на курсе я добавила много практик из ЛФК, благодаря которым улучшится состояние вашей спины
                </div>
            </div>
            <div className={style.contraindicationsBlock}>
                <div className={style.contraindicationsMainText}>
                    противопоказания
                </div>
                <div className={style.whiteBollBlockMain}>
                <div className={style.whiteBollBlock1}>
                    <div>
                    <svg className={style.whiteBoll} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <circle cx="14" cy="14" r="14" fill="white" />
                    </svg>
                    </div>
                    <div className={style.whiteText1}>
                        острые боли
                        <p> и</p>
                        воспалительный процесс
                    </div>
                </div>
                <div className={style.whiteBollBlock2}>
                    <div>
                    <svg className={style.whiteBoll} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                        <circle cx="14" cy="14" r="14" fill="white" />
                    </svg>
                    </div>
                    <div className={style.whiteText1}>
                        беременность
                    </div>
                </div>
                </div>
              
               

            </div>

        </div>
    )
}