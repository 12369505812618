import style from "./Course.module.css"
import instagrammIMG from "./IMG/instagram.png"
import tiktokIMG from "./IMG/tiktok.png"
import whatsAppIMG from "./IMG/whatsapp.png"
import youTubeIMG from "./IMG/youtube.png"
import asanaIMG from "./IMG/947190ed95e57cc17dd963ee59b2b3f9.jpeg"
import logoIMG from "./IMG/f345ae5a77d8fd586d537fd73c3edbaa.png"





export function Course() {
    return (
        <div className={style.courseBlock}>
            <div className={style.iconsBlock}>
                <a href="https://wa.me/34624494807" > <img src={whatsAppIMG} /> </a>
                <img src={tiktokIMG} />
                <a href="https://youtube.com/channel/UCndnnai18D-QPHyaPHq2Bqg" > <img src={youTubeIMG} /></a>
                <a href="https://www.instagram.com/fitness_samarskaia/" > <img src={instagrammIMG} /></a>
            </div>
            <div className={style.textFirst}>курс</div>
            <div className={style.textSecond}>«вдохни полной грудью»</div>
            <div className={style.asanaImgBlock}>
                <img src={asanaIMG} className={style.asanaImg} />
            </div>
            <div className={style.textTimeOfCoureseBlock}>
                <div className={style.textTimeOfCoureseOne}>
                    Длительность курса
                </div>
                <div className={style.textTimeOfCoureseTwo}>
                    21 день
                </div>
            </div>
            <div className={style.inventoryBlock}>
                <img src={logoIMG} className={style.logoImg} />

                <div className={style.inventaryTextBlock}>
                    <div className={style.importantInventaryText}>необходимый инвентарь</div>
                    <div className={style.inventaryText} >коврик, ролл, телефон</div>
                </div>
            </div>

            <div className={style.priceBlock}>


                <div className={style.priceMainText}>стоимость курса</div>

                <div className={style.costsBlock}>
                    <div className={style.priceSale}>
                        <div>70 €</div>
                        <div>6000 ₽</div>
                    </div>
                    <div className={style.priceMain}>
                        <div>50 €</div>
                        <div>4500 ₽</div>
                    </div>
                </div>
            </div>
               <div className={style.btnBlock}>
               <button className={style.btn}>
                <div className={style.btnText}>
                <a className={style.btnText} href="https://wa.me/34624494807">
                            <p>перейти</p>
                            к оплате
                            </a>
                </div>
            </button>
               </div>
           



        </div>


    )
}