import logo from './logo.svg';
import './App.css';
import { Header } from './Components/Header/Header';
import { Course } from './Components/Pages/Course/Course';
import { For } from './Components/Pages/For/For';
import { TrialTraining } from './Components/Pages/TrialTraining/TrialTraining';
import { OnCourse } from './Components/Pages/OnCourse/OnCourse';
import { Guide } from './Components/Guide/Guide';
import { Footer } from './Components/Footer/Footer';


function App() {
  return (
    <div className="App">
      <Header/>
      {/* <Guide/> */}
       <>
      <Course />
      <For />
      <TrialTraining />
      </> 
     
    </div>
  );
}

export default App;
