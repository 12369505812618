import style from "./OnCourse.module.css"
import imgError from "../Course/IMG/error.png"

export function OnCourse() {
    return (
        <div className={style.onCourseBlock}>
            <div className={style.titleMain}>
                на курсе
            </div>
            <div className={style.titleSecond}>
                4 блока:
            </div>
            



            <div className={style.blockOne}>
            <div className={style.one}>
                <p className={style.pOne}>
                1
                </p>
                </div>
                <div className={style.textBlockOne}>

                <div className={style.titleOne}>
                    «Стимуляция
                    <br/>
                    позвоночного столба»
                </div>
                <div className={style.descriptionOne}>
                Эта тренировка насытит организм кислородом,
                <br/>
                активизирует мышцы спины, раскроет грудной 
               <br/>
                отдел и придаст тонус спине!
                </div>
                </div>
            </div>





            <div className={style.blockTwo}>
            <div className={style.two}>
                <p className={style.pTwo}>
                2
                </p>
                </div>

                <div className={style.textBlockTwo}>

                <div className={style.oneTitleTwo}>
                «здоровая спина»
                </div>

                <div className={style.twoTitleTwo}>
                упражнения на стрессоустойчивость
                </div>

                <div className={style.descriptionTwo}>
               Эта тренировка поможет снять стресс 
               <div className={style.co}>
               и напряжение в теле. Вы познакомитесь со
               </div>
                 своим телом заново. Будьте готовы
                 <br/>
                 дышать много!
                </div>
                </div>
            </div>






            <div className={style.blockThree}>
            <div className={style.three}>
                <p className={style.pthree}>
                3
                </p>
                </div>
                <div className={style.textBlockThree}>

                <div className={style.oneTitleThree}>
                    проработка грудного
                    <br/>
                    и поясничного отделов
                </div>

                <div className={style.TwoTitleThree}>
                проработка сердечной чакры
                </div>
                

                <div className={style.descriptionThree}>
                Тренировка направлена на создание 
                <br/>
                красивой прямой осанки и улучшение 
                <br/>
                работы ЖКТ (внутренних органов)
                </div>
                </div>
            </div>


            <div className={style.blockFour}>
            <div className={style.four}>
                <p className={style.pFour}>
                4
                </p>
                </div>
                <div className={style.textBlockFour}>

                <div className={style.oneTitleFour}>
                дополнительный блок
                </div>

                <div className={style.twoTitleFour}>
                расслабление мфр
                </div>

                <div className={style.descriptionFour}>
                (дополнительный урок вы можете выполнять 
                <br/>
                по желанию. МФР поможет снять напряжение и 
                <br/>
                расслабить мышцы)
                </div>
                </div>
            </div>
            <div className={style.blockEthenshen}>
                        <img src={imgError} className={style.imgEtn} />

                        <div className={style.textEtn}>
                        рекомендуется выполнять упражнения утром, так
                        <br/>
                        как они способствуют активизации нервной системы
                        <br/>
                        и положительно влияет на твое пробуждение!
                        </div>
                    </div>
        </div>
    )
}