import style from "./Header.module.css"

export function Header() {
    return (
        <div className={style.container}>
            <div className={style.header}>
            <div className={style.titleMain}>
                Фитнес для души и тела
            </div>
            <div className={style.titleSecond}>
                Добро пожаловать в мир здоровья, грации и красоты
                ВЕСЕЛО, ЛЕГКО И С ДРАЙВОМ!
            </div>
            </div>
           
        </div>
    )
}