import style from "./Cost.module.css"
import imgMainCost from "./33944b202648082dfa356e9b8ad3ed73.png"
import imgSecondCost from "./photo1693047820 (5) 1 (1).png"
import imgspecialForYou from "./f74655bfa178fb9aa28e3a0e29c0b703.png"
import imgSandra from "./2864d8a7da4c6262b8470018f5874ea1.jpeg"
import imgPoseOne from "./Screenshot 2023-10-19 at 21.59.57.png"
import imgPoseTwo from "./Screenshot 2023-10-19 at 21.59.57 2.png"
import imgPoseThree from "./Screenshot 2023-10-19 at 21.59.57 3.png"
import { Footer } from "../../Footer/Footer"

export function Cost() {
    return (
        <div className={style.costBlock}>
            <div className={style.titleMain}>
                в стоимость
            </div>
            <div className={style.titleSecond}>
                курса входит:
            </div>
            <img src={imgMainCost} className={style.imgMain} />
            <div className={style.blockRedOne}>
                <div className={style.titleBlockRedOne}>
                    курс
                </div>
            </div>
            <div className={style.blockRedTwo}>
                <div className={style.titleBlockRedTwo}>
                    рекомендации
                    по питанию
                </div>
            </div>
            <div className={style.blockRedThree}>
                <div className={style.titleBlockRedThree}>
                    обратная связь
                    <br />
                    со мной 24/7
                </div>
            </div>
            <div className={style.blockRedFour}>
                <div className={style.titleBlockRedFour}>
                    дополнительный
                    <br />
                    урок по мфр
                </div>
            </div>
            <div className={style.lengthCourseBlock}>
                <div className={style.text21Day}>
                    длительность курс
                    <br />
                    21 день
                </div>
                <div className={style.text30minet}>
                    длительность 1 урока
                    <br />
                    30 минут
                </div>
            </div>
            <div className={style.priceListBlock}>
                <div className={style.priceListBlockTitle}>
                    стоимость курса
                </div>
                <div className={style.costsBlock}>
                    <div className={style.priceSale}>
                        <div>70 €</div>
                        <div>6000 ₽</div>
                    </div>
                    <div className={style.priceMain}>
                        <div>50 €</div>
                        <div>4500 ₽</div>
                    </div>
                </div>

            </div>
            <img src={imgSecondCost} className={style.imgSecondBlockPrice} />
            <div className={style.btnBlock}>
                <button className={style.btn}>
                    <div className={style.btnText}>
                        <a className={style.btnText} href="https://wa.me/34624494807">
                            <p>перейти</p>
                            к оплате
                            </a>

                    </div>
                </button>
            </div>


            <div className={style.specialForYouBlock}>
                <div className={style.descriptionText}>
                    Перед началом курса
                    <br />
                    я провожу с каждым
                    <br />
                    бесплатную консультацию,
                    <br />
                    на которой мы с вами
                    <br />
                    выявим ваши пожелания
                    <br />
                    и, исходя из ваших
                    <br />
                    запросов и данных,
                    <br />
                    скорректируем упражнения
                    <br />
                    персонально для тебя
                </div>
                <img src={imgspecialForYou} className={style.imgspecialForYou} />
            </div>

            <div className={style.samarskaiaContainer}>
                <div className={style.samarskaiaBlock}>
                    <div className={style.sashaBlock}>
                        <img src={imgSandra} className={style.sandraImg} />
                    </div>

                    <div className={style.aboutMeBlock}>

                        <div className={style.croupOne}>

                            <img src={imgPoseOne} className={style.poseImgOne} />

                            <div className={style.poseTextOne}>
                                мне 27 лет
                            </div>
                        </div>
                        <div className={style.croupTwo}>
                            <img src={imgPoseTwo} className={style.poseImgTwo} />
                            <div className={style.poseTextTwo}>
                                я фитнес тренер
                            </div>
                        </div>
                        <div className={style.croupThree}>
                            <img src={imgPoseThree} className={style.poseImgThree} />
                            <div className={style.poseTextThree}>
                                помогла 200+
                                <br />
                                женщинам
                                <br />
                                вернуть здоровье,
                                <br />
                                красоту,
                                <br />
                                уверенность
                                <br />
                                и грацию
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.metodicTitle}>
                    уникальность
                    <br />
                    моей методики
                </div>

                <div className={style.threeInOne}>
                    3в1
                </div>

                <div className={style.metodicBlock}>
                    <div className={style.metodicText}>
                        пилатес
                        <br />
                        растяжка
                        <br />
                        дыхательные практики
                        <br />
                        из кундалини йоги
                    </div>
                </div>
                <div className={style.descriptionBlockFooter}>
                    <div className={style.descriptionTextFooter}>
                        я убеждена в том, что нужно работать комплексно
                        со всем организмом в целом.
                        <br />
                        Это дает качественный результат.
                        <br />
                        все тренировки я провожу через осознанность
                        и доношу вам простым, но эффективным языком
                    </div>
                </div>
                <Footer />
            </div>

        </div>
    )
}
