import style from"./Footer.module.css"
import imgCallMe from"./43894eac32387ab415a0789fbd6c4c20.png"
import LogoIMGFooter from "./photo1693047810 (1) 2.png"

export function Footer(){
    return(
        <div className={style.footerBlock}>
           <div  className={style.textMeBlock}>
           <a href="https://wa.me/34624494807">  <img src={imgCallMe} className={style.textMeIMG}/></a>
           <a href="https://wa.me/34624494807" className={style.textMeText}>  связь со мной  </a>
           </div>
           <img src={LogoIMGFooter} className={style.logoImgFooter}/>
        </div>
    )
}