import style from "../TrialTraining/TrialTraining.module.css"
import imgOneForTrialTaining from ".//a5378f5c472857695425e7ba69633932.png"
import imgTwoForTrialTaining from ".//photo1693047820 (3) 1.png"
import imgThreeForTrialTaining from ".//2794dba5c2107e6d40ad69ffb8d982db.png"
import imgError from "../Course/IMG/error.png"
import { OnCourse } from "../OnCourse/OnCourse"
import { Cost } from "../Cost/Cost"
import { Footer } from "../../Footer/Footer"


export function TrialTraining() {
    return (
        <div className={style.container}>
            <div className={style.textMain}>
                пробная тренировка
            </div>
            <div className={style.textSecond}>
                «вдохни полной грудью»
            </div>
            <div className={style.videoYouTubeTrainingFree}>
            <iframe className={style.videoYouTubeTrainingFree} width="857" height="416" src="https://www.youtube.com/embed/-XTCuwxoENk?si=yn63IRb53MXq2x_O" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div className={style.blockOnCourse}>
                <div className={style.onCourse}>
                    <div className={style.onCourseTextMain}>
                        на курсе
                    </div>
                    <div className={style.onCourseTextSecond}>
                        вас ждут
                    </div>
                    <div className={style.DescriptioBlockOne}>
                        <div className={style.textDescriptionOne}>
                            Фитнес упражнения на растяжку
                            и укрепление спины и грудного отдела
                            через дыхательные практики.
                        </div>
                        <div className={style.textDescriptionTwo}>
                            Фитнес упражнения укрепят вашу спину,
                            <div>
                                уберут мышечные зажимы, вернут

                            </div>
                            <div>
                                пластичность вашей спине
                            </div>

                        </div>
                    </div>
                    <img src={imgOneForTrialTaining}
                        className={style.ImgOneForTrialTaining} />

                    <div className={style.textDescriptionBlockTwo}>
                        Дыхательные практики из Кундалини йоги насытят организм кислородом,
                        улучшат работу внутренних органов, снимут напряжение в спине,
                        уйдет боль.
                        <div>
                            Вам станет легче дышать
                        </div>
                    </div>
                    <img src={imgTwoForTrialTaining}
                        className={style.ImgTwoForTrialTaining} />

                    <div className={style.textDescriptionBlockThree}>
                        Медитативные техники на расслабление помогут вам расслабить ваши ум и тело.
                        Вы научитесь слышать себя и свое тело
                    </div>
                    <img src={imgThreeForTrialTaining}
                        className={style.ImgThreeForTrialTaining} />

                    <div className={style.blockEthenshen}>
                        <img src={imgError} className={style.imgEtn} />

                        <div className={style.textEtn}>
                        Чтоб получить полный эффект от курса,
                        <div>
                        выполняйте каждый блок 4 раза в неделю,
                        </div>
                          т.е. через день
                        </div>
                    </div>
                </div>
            </div>
            <OnCourse/>
            <Cost/>
          
        </div>
    )
}